import React from 'react'
export default function Faq() {
  return (
    <>
   <section className="page-bread">
<div className='container'>
    <div className='row'>
        <div className='col-md-12 text-center'>
            <h2>Faq's</h2>
            
            
        </div>
    </div>
</div>
</section> 
   <section className='frequently'>
<div className='container'>
  <div className='row'>
    <div className='col-md-12 text-center p-3'>
    <h2>Frequently Ask Qustions</h2>
      <p>Pellentesque cras adipiscing tempus libero vel nullam mauris tellus. Aliquam ultrices tellus consequat amet, lectus aliquam est in neque.</p>
    </div>
    <div className='col-md-1'></div>
    <div className='col-md-10'>
     
<div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button
        className="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        How Can I Download &amp; Install StrinZ App on my Android phone?
      </button>
    </h2>
    <div
      id="collapseOne"
      className="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            {" "}
            Go to the <strong>Google Play Store,</strong> search for{" "}
            <strong> StrinZ</strong> app. Tap <strong>INSTALL.</strong>
          </li>
          <li>
            {" "}
            Open <strong>StrinZ </strong> and get started after{" "}
            <strong>“Verify”</strong> your phone number.
          </li>
          <li>
            {" "}
            To continue, <strong>Create</strong> your <strong>Profile.</strong>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        aria-expanded="false"
        aria-controls="collapseTwo"
      >
        How Can I uninstall StrinZ App from my Android phone?
      </button>
    </h2>
    <div
      id="collapseTwo"
      className="accordion-collapse collapse"
      aria-labelledby="headingTwo"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        We recommend using the <b>Chat Back-up</b> feature to back up your
        messages before you uninstall StrinZ from your device:
        <br />
        <b>TAP Settings&gt; Delete Account &gt; Choose Backup</b>
        <ol>
          <li>
            Go to your <b>device's Settings.</b>
          </li>
          <li>
            <b>Tap Apps &amp; notifications </b>
          </li>
          <li>
            <b>Search &amp; Select StrinZ </b>
          </li>
          <li>
            <b>Uninstall</b> to remove the app and all its data.
          </li>
        </ol>
      </div>
    </div>
  </div>
  {/* How Can I Download & Install StrinZ App on my IOS phone?*/}
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseThree"
        aria-expanded="false"
        aria-controls="collapseThree"
      >
        How Can I Download &amp; Install StrinZ App on my IOS phone?
      </button>
    </h2>
    <div
      id="collapseThree"
      className="accordion-collapse collapse"
      aria-labelledby="headingThree"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Find <b>StrinZ App</b> in the <b>Apple App Store</b>, then tap{" "}
            <b>GET.</b>
          </li>
          <li>
            Open <b>StrinZ</b> and get started by <b>“Verify”</b> your phone
            number.
          </li>
          <li>
            To continue, Create your Profile. You can also change this later by
            tapping &gt;<b> Settings &gt; Edit Profile.</b>
          </li>
        </ol>
      </div>
    </div>
  </div>
  {/*How Can I uninstall StrinZ App from my IOS phone?*/}
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingfour">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsefour"
        aria-expanded="false"
        aria-controls="collapsefour"
      >
        How Can I uninstall StrinZ App from my IOS phone?
      </button>
    </h2>
    <div
      id="collapsefour"
      className="accordion-collapse collapse"
      aria-labelledby="headingfour"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        We recommend using the Back-up feature to back up your messages before
        you uninstall StrinZ from your device:
        <br />
        <b>TAP Settings&gt; Delete Account &gt; Choose Backup</b>
        <ol>
          <li>
            Touch and hold the <b>StrinZ</b> icon on the <b>Home Screen</b>{" "}
            until the icons start to wiggle.
          </li>
          <li>
            Tap the <b>X</b> in the corner of the <b>StrinZ</b> icon.
          </li>
          <li>
            Tap <b>Delete</b> to remove the app.
          </li>
        </ol>
        <b>Note:</b>If you have issues with your Apple ID, password, or
        downloading in general, please contact Apple Support. Apple controls the
        download and installation process for all iPhone apps. We cannot resolve
        any Apple App Store related issues.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingfive">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsefive"
        aria-expanded="false"
        aria-controls="collapsefive"
      >
        How to update StrinZ App?
      </button>
    </h2>
    <div
      id="collapsefive"
      className="accordion-collapse collapse"
      aria-labelledby="headingfive"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        We encourage you to always use the most up to date version of{" "}
        <b>StrinZ App</b>. Latest versions contain the newest features and bug
        fixes.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingsix">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsesix"
        aria-expanded="false"
        aria-controls="collapsesix"
      >
        Android
      </button>
    </h2>
    <div
      id="collapsesix"
      className="accordion-collapse collapse"
      aria-labelledby="headingsix"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        Find <b>StrinZ</b> App in the Google Play Store, then tap <b>Update.</b>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingseven">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseseven"
        aria-expanded="false"
        aria-controls="collapseseven"
      >
        iPhone
      </button>
    </h2>
    <div
      id="collapseseven"
      className="accordion-collapse collapse"
      aria-labelledby="headingseven"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        Find <b>StrinZ</b> App in the Apple App Store, then tap <b> Update.</b>
        <br />
        <b> Note:</b>When registering your phone number, you’ll need to use the
        latest version of StrinZ, otherwise your registration might fail. You
        can easily update StrinZ App from your phone’s app store
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingeight">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseeight"
        aria-expanded="false"
        aria-controls="collapseeight"
      >
        How To Register &amp; Verify Phone Number?
      </button>
    </h2>
    <div
      id="collapseeight"
      className="accordion-collapse collapse"
      aria-labelledby="headingeight"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>You can only register a phone number you own.</li>
          <li>
            You must be able to receive <b> WhatsApp</b> to the phone number you
            are trying to register.
          </li>
          <li>
            You must have any call-blocking settings, apps or task killers
            disabled
          </li>
          <li>
            You must have a working internet connection through mobile data or
            Wi-Fi. If you are roaming or have a bad connection, registration may
            not work.
          </li>
          <li>
            To check if you are connected to the internet, try opening{" "}
            <b>www.strinz.online </b> on your phone’s Internet browser.
          </li>
        </ol>
        <b> Note:</b> You must have an active SIM card for the phone number you
        are activating
        <h4>Steps:</h4>
        <ol>
          <li>Enter your phone number:</li>
          <li>
            Choose your country from the drop-down list. This will automatically
            fill your country code on the left.
          </li>
          <li>
            Enter your phone number in the box on the right. Do not put 0 before
            your phone number.
          </li>
          <li>Tap Verify to request a code.</li>
          <li>
            Enter the 4-digit code you receive via <b>WhatsApp</b>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingnine">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsenine"
        aria-expanded="false"
        aria-controls="collapsenine"
      >
        How will I receive a Notification of new StrinZ?
      </button>
    </h2>
    <div
      id="collapsenine"
      className="accordion-collapse collapse"
      aria-labelledby="headingnine"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ul>
          <li>
            You will receive an <b> WhatsApp</b> from <b>StrinZ App </b> to
            notify, if you are <b>not a registered user</b> of <b>StrinZ App</b>
            , you can see the StrinZ by a click on a link in WhatsApp, we
            strongly recommend you to download the StrinZ App to access full
            features of the App
          </li>
          <li>
            If you are a <b>registered user</b> of <b>StrinZ App,</b> you will
            receive notification of new <b>StrinZ</b> on your phone{" "}
            <b>home screen</b> if you are not using the <b>StrinZ </b>App.
          </li>
          <li>
            If you are a <b>registered user</b> and<b> online on StrinZ App </b>
            then you will receive notification within the <b> StrinZ App.</b>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingten">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseten"
        aria-expanded="false"
        aria-controls="collapseten"
      >
        How will I open new StrinZ from notification?
      </button>
    </h2>
    <div
      id="collapseten"
      className="accordion-collapse collapse"
      aria-labelledby="headingten"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        To open a new StrinZ:
        <ol>
          <li>
            <b>Click</b> on link sent to you by <b>WhatsApp</b>
          </li>
          <li>
            <b>Tap</b> the <b>notification</b> on <b>Home Screen</b>
          </li>
          <li>
            <b> Click </b> on a <b>Chat</b> with the <b>notification Icon</b>
          </li>
        </ol>
        <h4>About using StrinZ App abroad:</h4>
        When you are traveling out of your country, you can still use StrinZ App
        via mobile data or Wi-Fi.
        <br />
        If you use a local SIM card while you are traveling, you can still use{" "}
        <b>StrinZ App</b> with your home number. However, in this case, if you
        need to re-verify your account, you will not be able to do this while
        using the local SIM card. To re-verify / verify a phone number with
        StrinZ, you must have the corresponding SIM card in your phone, with
        phone or WhatsApp service enabled.
        <p />
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingeleven">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseeleven"
        aria-expanded="false"
        aria-controls="collapseeleven"
      >
        What If I did not receive the 4-digit code by WhatsApp?
      </button>
    </h2>
    <div
      id="collapseeleven"
      className="accordion-collapse collapse"
      aria-labelledby="headingeleven"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            {" "}
            Wait for the progress bar to finish and retry. The wait may take up
            to 10 minutes.
          </li>
          <li>
            {" "}
            Do not guess the code, or you will be locked out for some time.
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwelve">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsetwelve"
        aria-expanded="false"
        aria-controls="collapsetwelve"
      >
        What If I’m not able to register by OTP?
      </button>
    </h2>
    <div
      id="collapsetwelve"
      className="accordion-collapse collapse"
      aria-labelledby="headingtwelve"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Turning your <b>phone off, </b> waiting for 30 seconds, and{" "}
            <b>turning it back on.</b>
          </li>
          <li>
            <b>Deleting</b> and <b>reinstalling</b> the latest version of{" "}
            <b>StrinZ App.</b>
          </li>
          <li>
            Send a test WhatsApp message from any phone to your own phone number
            exactly as you entered it in StrinZ, including the country code, to
            check your reception.
          </li>
        </ol>
        <h4>If you have issues registering, try the following:</h4>
        <ol>
          <li>
            Make sure you have an <b>active SIM card</b> for the number you are
            trying to register.
          </li>
          <li>
            <b>Reboot your phone</b> (to reboot your phone, turn it off, wait
            for 30 seconds, and turn it back on).
          </li>
          <li>
            <b>Delete and reinstall</b> the latest version of <b>StrinZ App.</b>
          </li>
          <li>
            <b>Send a test WhatsApp</b> message from any phone to your own phone
            number exactly as you entered it in StrinZ App. Make sure to include
            the country code, and check your reception.
          </li>
        </ol>
        <p>
          <b>Note:</b> We cannot send you the code by email due to security
          reasons.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwelve">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsethirteen"
        aria-expanded="false"
        aria-controls="collapsethirteen"
      >
        What If I forget my 4 Digit PIN?
      </button>
    </h2>
    <div
      id="collapsethirteen"
      className="accordion-collapse collapse"
      aria-labelledby="headingthirteen"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <p>
          <b>If you are Logged-out,</b> reset your <b>PIN</b> by following these
          steps:
        </p>
        <ol>
          <li>
            Open <b>StrinZ App</b>
          </li>
          <li>
            <b>Click</b> on <b>Forgot PIN</b>
          </li>
          <li>
            You will receive a <b>4 Digit OTP</b> by <b>WhatsApp </b> on your{" "}
            <b>registered mobile number</b>
          </li>
          <li>
            <b>Enter 4 Digit OTP</b> to <b>Verify</b>
          </li>
          <li>
            Once Verified, you will be redirected to <b>Reset PIN</b>
          </li>
          <li>
            <b>Enter</b> new <b>4 Digit PIN</b>
          </li>
          <li>
            <b>Confirm</b> new <b>4 Digit PIN</b>
          </li>
          <li>
            <b>Click Submit</b> to save new <b>PIN</b>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwelve">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseforteen"
        aria-expanded="false"
        aria-controls="collapseforteen"
      >
        How To Create profile on StrinZ App?
      </button>
    </h2>
    <div
      id="collapseforteen"
      className="accordion-collapse collapse"
      aria-labelledby="headingforteen"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <p>
          Open <b>StrinZ App</b> on your registered phone&gt;<b>Verify OTP</b>,
          if you are using the App for the first time then you will be
          redirected to <b>Create Profile:</b>
        </p>
        <h4>Steps:</h4>
        <ol>
          <li>
            Click on <b>Image Icon </b> to choose profile Avatar which represent
            you.
          </li>
          <li>
            Enter <b>Full Name</b>
          </li>
          <li>
            Enter <b>User Name</b>
          </li>
          <li>
            Choose <b>Gender</b>
          </li>
          <li>
            Create <b>PIN:</b> Enter <b>4 Digit PIN,</b>, Re-enter{" "}
            <b>4 Digit PIN</b> to confirm PIN
          </li>
          <li>
            Confirm your <b>AGE</b> by a click, confirm that you are 13 years+
            of age
          </li>
          <li>
            <b>Read &amp; Accept T&amp;C’s</b> by click on box
          </li>
          <li>
            <b>Click NEXT</b> to Save profile
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingfifteen">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsefifteen"
        aria-expanded="false"
        aria-controls="collapsefifteen"
      >
        Can I Use Emoji in my Name or User Name on StrinZ App?
      </button>
    </h2>
    <div
      id="collapsefifteen"
      className="accordion-collapse collapse"
      aria-labelledby="headingfifteen"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <p>
          In current version, you cannot create a <b>Name</b> or{" "}
          <b>User Name</b> containing <b>Emoji</b>
          We are working continuously to improve user’s experience.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingsixteen">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsesixteen"
        aria-expanded="false"
        aria-controls="collapsesixteen"
      >
        Can I access device camera or gallery to upload an image as profile pic
        on StrinZ App?
      </button>
    </h2>
    <div
      id="collapsesixteen"
      className="accordion-collapse collapse"
      aria-labelledby="headingsixteen"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <p>
          In current version, you cannot use your <b>camera</b> or{" "}
          <b>phone gallery</b> to upload an image, you can only choose one of
          the <b>Avatars</b> available on <b>StrinZ</b> app We are working
          continuously to improve user’s experience.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingseventeen">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseseventeen"
        aria-expanded="false"
        aria-controls="collapseseventeen"
      >
        Can I choose not to disclose my Gender on StrinZ App?
      </button>
    </h2>
    <div
      id="collapseseventeen"
      className="accordion-collapse collapse"
      aria-labelledby="headingseventeen"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <p>
          In current version, declaration of <b>Gender</b> is mandatory for the
          functioning of <b>StrinZ App. StrinZ</b> are generated based on
          relationship among users and it is not possible to generate a suitable
          message without knowing the gender. We are working continuously to
          improve user’s experience.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingeighteen">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseeighteen"
        aria-expanded="false"
        aria-controls="collapseeighteen"
      >
        How to add a New Contact in StrinZ App?
      </button>
    </h2>
    <div
      id="collapseeighteen"
      className="accordion-collapse collapse"
      aria-labelledby="headingeighteen"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat.</b>
          </li>
          <li>
            Click on <b>Create StrinZ</b> Icon
          </li>
          <li>
            Select <b>New Contact&gt;Proceed,</b> you will be redirected to
            enter details of new contact:
          </li>
          <ul>
            <li>Name</li>
            <li>Mobile Number with Country Code</li>
          </ul>
        </ol>
        <p>
          Click <b>Submit</b> to save contact.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingnineteen">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsenineteen"
        aria-expanded="false"
        aria-controls="collapsenineteen"
      >
        How to send StrinZ to a Contact from phone book?
      </button>
    </h2>
    <div
      id="collapsenineteen"
      className="accordion-collapse collapse"
      aria-labelledby="headingnineteen"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat</b>
          </li>
          <li>
            Click on <b>Create StrinZ</b> Icon
          </li>
          <li>
            Click <b>Contacts</b>
          </li>
          <li>
            Select <b>Contact</b> from list of saved contacts
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwenty">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsetwenty"
        aria-expanded="false"
        aria-controls="collapsetwenty"
      >
        How to Choose a Relationship?
      </button>
    </h2>
    <div
      id="collapsetwenty"
      className="accordion-collapse collapse"
      aria-labelledby="headingtwenty"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat</b>
          </li>
          <li>
            Click on <b>Create StrinZ</b> Icon
          </li>
          <li>
            Select <b>Contact</b> accordingly
          </li>
          <li>
            Click drop down Icon to select <b>Relationship</b> from the list
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwentyone">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsetwentyone"
        aria-expanded="false"
        aria-controls="collapsetwentyone"
      >
        How to Choose Emotions?
      </button>
    </h2>
    <div
      id="collapsetwentyone"
      className="accordion-collapse collapse"
      aria-labelledby="headingtwentyone"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat</b>
          </li>
          <li>
            Click on <b>Create StrinZ Icon</b>
          </li>
          <li>
            Select <b>Contact</b> accordingly
          </li>
          <li>
            Click drop down Icon to select <b>Relationship</b> from the list
          </li>
          <li>Choose Emotion Icon based on your choice</li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwentytwo">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsetwentytwo"
        aria-expanded="false"
        aria-controls="collapsetwentytwo"
      >
        How to Create StrinZ?
      </button>
    </h2>
    <div
      id="collapsetwentytwo"
      className="accordion-collapse collapse"
      aria-labelledby="headingtwentytwo"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat</b>
          </li>
          <li>
            Click on <b>Create StrinZ</b> Icon
          </li>
          <li>
            Select <b>Contact</b> accordingly
          </li>
          <li>
            Click <b>drop down Icon</b> to select <b>Relationship</b> from the
            list
          </li>
          <li>
            Choose <b>Emotion Icon</b> of your choice
          </li>
          <li>
            Click <b>Create StrinZ</b>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwenty3">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsetwenty3"
        aria-expanded="false"
        aria-controls="collapsetwenty3"
      >
        How To Select StrinZ?
      </button>
    </h2>
    <div
      id="collapsetwenty3"
      className="accordion-collapse collapse"
      aria-labelledby="headingtwenty3"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            You will see a list of <b>StrinZ</b> with a click on{" "}
            <b>Create your StrinZ</b>
          </li>
          <li>
            <b>Scroll Up</b> or <b>Down</b> for more <b>StrinZ</b> to choose
          </li>
          <li>
            Tap on selected <b>StrinZ</b>
          </li>
          <li>
            Choose <b>Edit</b> or<b>Select</b> to share <b>StrinZ</b>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwenty4">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsetwenty4"
        aria-expanded="false"
        aria-controls="collapsetwenty4"
      >
        How to Edit StrinZ?
      </button>
    </h2>
    <div
      id="collapsetwenty4"
      className="accordion-collapse collapse"
      aria-labelledby="headingtwenty4"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <p>
          {" "}
          “You cannot change the text of <b>StrinZ”</b>
          <br />
          However, you can change the look n feel of StrinZ by changing the
          background, font, text or add emoji to your message
        </p>
        <ol>
          <li>
            <b>Choose StrinZ&gt;Edit&gt;</b> Tap on <b>change colour option</b>,
            you can change background or text colour from a list of options
          </li>
          <li>
            <b>Choose StrinZ&gt;Edit&gt;</b> Tap on <b>add element option,</b>{" "}
            you can add emoji’s/sticker on top of your message from a list of
            emoji’s
          </li>
          <li>
            <b>Choose StrinZ&gt;Edit&gt;</b> Tap on <b>change font,</b> you can
            change font of your message from suggested options{" "}
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwenty5">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsetwenty5"
        aria-expanded="false"
        aria-controls="collapsetwenty5"
      >
        How can I send StrinZ without editing?
      </button>
    </h2>
    <div
      id="collapsetwenty5"
      className="accordion-collapse collapse"
      aria-labelledby="headingtwenty5"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <p> Yes, you can send message without editing</p>
        <ol>
          <li>
            <b>Choose StrinZ</b>
          </li>
          <li>
            <b>Click on message</b>
          </li>
          <li>
            <b>Choose SELECT</b> you will see a preview of StrinZ{" "}
          </li>
          <li>
            <b>Click “SEND”</b> to <b>share</b> StrinZ
          </li>
        </ol>
        <p>
          You can also choose to edit with a click on <b>EDIT Button.</b>
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwenty6">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsetwenty6"
        aria-expanded="false"
        aria-controls="collapsetwenty5"
      >
        How can I access my previous chats?
      </button>
    </h2>
    <div
      id="collapsetwenty6"
      className="accordion-collapse collapse"
      aria-labelledby="headingtwenty6"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <p>
          Open <b>StrinZ</b> on your registered phone.
          <br />
          <b>If you are logged-In,</b> you will be automatically redirected to{" "}
          <b>Chat StrinZ</b>
          You will see all the previous chats on <b>Chat page</b>
          <br />
          <br />
          <b>If you are logged-Out (Due to any reason):</b>
          <br />
          Open <b>StrinZ&gt;Enter 4 Digit PIN</b>, you will be automatically
          redirected to Chat StrinZ
          <br /> <b> Note:</b> You will see all the previous chats on{" "}
          <b>Chat page</b>
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwenty7">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsetwenty7"
        aria-expanded="false"
        aria-controls="collapsetwenty7"
      >
        How to send reply to StrinZ?
      </button>
    </h2>
    <div
      id="collapsetwenty7"
      className="accordion-collapse collapse"
      aria-labelledby="headingtwenty7"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <p>
          Open <b>StrinZ</b> on your registered phone, Go to Chat.
        </p>
        <h4>Option1</h4>
        <ol>
          <li>
            Open <b>Chat</b>
          </li>
          <li>
            Click on <b>StrinZ</b> you want to <b>Reply</b>
          </li>
          <li>
            <b>Choose Option Reply,</b> you will be redirected to{" "}
            <b>Create StrinZ</b>, you can create a new <b>StrinZ</b> to reply
          </li>
        </ol>
        <h4>Option2</h4>
        <ol>
          <li>
            Open <b>Chat</b>
          </li>
          <li>
            Click on <b>&gt;Emoji Icon</b> to reply with <b>Emoji</b>
          </li>
        </ol>
        <h4>Option3</h4>
        <ol>
          <li>
            Open <b>Chat</b>
          </li>
          <li>
            Click on <b>GIF Icon</b> to reply with <b>GIF</b>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwenty7">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsetwenty8"
        aria-expanded="false"
        aria-controls="collapsetwenty8"
      >
        How to delete a StrinZ?
      </button>
    </h2>
    <div
      id="collapsetwenty8"
      className="accordion-collapse collapse"
      aria-labelledby="headingtwenty8"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat</b>.
          </li>
          <li>
            Open <b>Chat</b>
          </li>
          <li>
            Click on <b>StrinZ</b> to <b>Delete</b>
          </li>
          <li>
            <b>Choose</b> option <b>Delete,</b> message will be deleted after
            your confirmation
          </li>
        </ol>
        <p>
          <b>Note:</b> You cannot retrieve the deleted StrinZ.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingtwenty7">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsetwenty9"
        aria-expanded="false"
        aria-controls="collapsetwenty9"
      >
        How to delete a Chat?
      </button>
    </h2>
    <div
      id="collapsetwenty9"
      className="accordion-collapse collapse"
      aria-labelledby="headingtwenty9"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <h4>Option1:</h4>
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat.</b>
          </li>
          <li>
            <b>Scroll left</b> on <b>Chat</b> to delete
          </li>
          <li>
            <b>Choose Option Delete</b> chat will be deleted after your
            confirmation
          </li>
        </ol>
        <h4>Option 2:</h4>
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to Chat.
          </li>
          <li>
            <b>Scroll left</b> on <b> Chat to delete</b>
          </li>
          <li>
            Choose <b>More options (:)</b> Icon
          </li>
          <li>
            Choose option <b>Delete,</b> chat will be <b> Deleted</b> after your
            confirmation{" "}
          </li>
          <p />
          <p>Note:</p> You cannot retrieve the deleted Chat.
          <p />
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingthirty">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsethirty"
        aria-expanded="false"
        aria-controls="collapsethirty"
      >
        How to Mute a Chat?
      </button>
    </h2>
    <div
      id="collapsethirty"
      className="accordion-collapse collapse"
      aria-labelledby="headingthirty"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat.</b>
          </li>
          <li>
            <b>Scroll left</b> on <b>Chat</b> to Mute
          </li>
          <li>
            Choose <b>More options (:)</b> Icon
          </li>
          <li>
            {" "}
            Choose Option <b>Mute,</b> Chat will be <b>Mute</b> after your
            confirmation{" "}
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingthirty1">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsethirty1"
        aria-expanded="false"
        aria-controls="collapsethirty1"
      >
        How to Unmute a Chat?
      </button>
    </h2>
    <div
      id="collapsethirty1"
      className="accordion-collapse collapse"
      aria-labelledby="headingthirty1"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat.</b>
          </li>
          <li>
            Click <b>Unmute Icon</b> in front of a Chat, Chat will be{" "}
            <b>Unmute</b> after your confirmation.
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingthirty2">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsethirty2"
        aria-expanded="false"
        aria-controls="collapsethirty2"
      >
        How to mark Chat as “Favourite”?
      </button>
    </h2>
    <div
      id="collapsethirty2"
      className="accordion-collapse collapse"
      aria-labelledby="headingthirty2"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat.</b>
          </li>
          <li>
            {" "}
            <b>Scroll left on Chat</b> you want to mark Favourite
          </li>
          <li>
            Choose <b>More options (:) Icon</b>
          </li>
          <li>
            Choose Option Favourite, chat will be marked as Favourite after your
            confirmation
          </li>
        </ol>
        <p>
          <b>Note:</b> Chats marked as Favourite will be displayed on top of
          Chat list.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingthirty3">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsethirty3"
        aria-expanded="false"
        aria-controls="collapsethirty3"
      >
        How to remove a chat as “Favourite”?
      </button>
    </h2>
    <div
      id="collapsethirty3"
      className="accordion-collapse collapse"
      aria-labelledby="headingthirty3"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat.</b>
          </li>
          <li>
            Click <b>Favourite Icon</b> in front of a Chat, Chat will be{" "}
            <b>removed</b> from <b>Favourite</b> after your confirmation
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingthirty4">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsethirty4"
        aria-expanded="false"
        aria-controls="collapsethirty4"
      >
        How to Block a Contact?
      </button>
    </h2>
    <div
      id="collapsethirty4"
      className="accordion-collapse collapse"
      aria-labelledby="headingthirty4"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat.</b>
          </li>
          <li>
            <b>Scroll left</b> on Chat to <b>Block</b>
          </li>
          <li>
            Choose <b>More options (:)</b> Icon
          </li>
          <li>
            Choose Option <b>Block StrinZ</b>, contact will be block after your
            confirmation
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingthirty5">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsethirty5"
        aria-expanded="false"
        aria-controls="collapsethirty5"
      >
        How to Unblock a Contact?
      </button>
    </h2>
    <div
      id="collapsethirty5"
      className="accordion-collapse collapse"
      aria-labelledby="headingthirty4"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b> Chat</b>
          </li>
          <li>
            Click <b>Unblock Icon</b> in front of a Chat, Chat will be{" "}
            <b>Unblock</b> after your confirmation
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingthirty6">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsethirty6"
        aria-expanded="false"
        aria-controls="collapsethirty6"
      >
        How to change profile Avatar?
      </button>
    </h2>
    <div
      id="collapsethirty6"
      className="accordion-collapse collapse"
      aria-labelledby="headingthirty6"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to Chat
          </li>
          <li>
            Click <b>Settings Icon</b>
          </li>
          <li>
            Click <b>Account Settings</b>
          </li>
          <li>
            Click <b>Image Icon,</b> you can choose your <b>Avatar</b> from{" "}
            <b>StrinZ App.</b>
          </li>
        </ol>
        <p>
          Note: you will be redirected to enter <b>PIN</b> to save changes
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingthirty7">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsethirty7"
        aria-expanded="false"
        aria-controls="collapsethirty7"
      >
        How to change User Name in profile?
      </button>
    </h2>
    <div
      id="collapsethirty7"
      className="accordion-collapse collapse"
      aria-labelledby="headingthirty7"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to Chat
          </li>
          <li>
            Click <b>Settings Icon</b>
          </li>
          <li>
            Click <b>Account Settings</b>
          </li>
          <li>
            <b>Type New User name</b> in User Name column
          </li>
        </ol>
        <p>
          <b>Note:</b> you will be redirected to enter PIN to save changes.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingthirty8">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsethirty8"
        aria-expanded="false"
        aria-controls="collapsethirty8"
      >
        How to change Gender in profile?
      </button>
    </h2>
    <div
      id="collapsethirty8"
      className="accordion-collapse collapse"
      aria-labelledby="headingthirty8"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to Chat
          </li>
          <li>
            <b>Click Settings Icon</b>
          </li>
          <li>
            <b>Click Account Settings</b>
          </li>
          <li>
            <b>Click on drop down Icon</b> in front of Relationship column
          </li>
          <li>Choose Gender from the list</li>
        </ol>
        <p>
          <b>Note:</b> you will be redirected to enter <b>PIN</b> to save
          changes.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingthirty9">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsethirty9"
        aria-expanded="false"
        aria-controls="collapsethirty9"
      >
        How to change Name in profile?
      </button>
    </h2>
    <div
      id="collapsethirty9"
      className="accordion-collapse collapse"
      aria-labelledby="headingthirty9"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to Chat
          </li>
          <li>
            <b>Click Settings Icon</b>
          </li>
          <li>
            <b>Click Account Settings</b>
          </li>
          <li>
            <b>Type New name</b> in Name column.
          </li>
        </ol>
        <p>
          {" "}
          <b>Note:</b> you will be redirected to enter PIN to save changes.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingforty">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseforty"
        aria-expanded="false"
        aria-controls="collapseforty"
      >
        How to change PIN for future login?
      </button>
    </h2>
    <div
      id="collapseforty"
      className="accordion-collapse collapse"
      aria-labelledby="headingforty"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat</b>
          </li>
          <li>
            <b>Click Settings Icon</b>
          </li>
          <li>
            <b>Account Settings</b>
          </li>
          <li>
            <b>Enter 4 Digit new PIN</b>
          </li>
          <li>
            <b>Re Enter New PIN to Confirm</b>
          </li>
          <li>
            Enter <b>Old PIN</b> to save changes.
          </li>
        </ol>
        <p>
          <b>Note:</b> you will be redirected to enter PIN to save changes.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingforty1">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseforty1"
        aria-expanded="false"
        aria-controls="collapseforty1"
      >
        How to Delete my account on StrinZ App?
      </button>
    </h2>
    <div
      id="collapseforty1"
      className="accordion-collapse collapse"
      aria-labelledby="headingforty1"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat</b>
          </li>
          <li>
            <b>Click Settings Icon</b>
          </li>
          <li>
            <b>Delete Account</b>{" "}
          </li>
          <li>
            <b>Create Back up (Optional)</b>
          </li>
          <li>
            Select a reason to <b>delete StrinZ</b> account
          </li>
          <li>
            Enter <b>PIN</b> to delete <b>StrinZ</b> account.
          </li>
        </ol>
        <p>
          Note: If you choose to <b>Delete</b> your account, all your
          information including your chats will be <b>deleted permanently.</b>
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingforty2">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseforty2"
        aria-expanded="false"
        aria-controls="collapseforty2"
      >
        How to Log out from StrinZ App?
      </button>
    </h2>
    <div
      id="collapseforty2"
      className="accordion-collapse collapse"
      aria-labelledby="headingforty2"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b> StrinZ</b> on your registered phone, Go to <b>Chat</b>
          </li>
          <li>
            <b>Click Settings Icon</b>
          </li>
          <li>
            <b>Click Log Out</b>
          </li>
        </ol>
        <p>
          <b>Note:</b> Once logged out, you need to enter 4 Digit Pin to
          Re-login to <b>StrinZ </b>
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingforty3">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseforty3"
        aria-expanded="false"
        aria-controls="collapseforty3"
      >
        How to access Privacy Policy of StrinZ App?
      </button>
    </h2>
    <div
      id="collapseforty3"
      className="accordion-collapse collapse"
      aria-labelledby="headingforty3"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat</b>
          </li>
          <li>
            <b>Click Settings Icon</b>
          </li>
          <li>
            <b>Click Privacy Policy</b>, you will be redirected to privacy
            policy
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingforty4">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseforty4"
        aria-expanded="false"
        aria-controls="collapseforty4"
      >
        How to access Terms and Conditions of StrinZ App?
      </button>
    </h2>
    <div
      id="collapseforty4"
      className="accordion-collapse collapse"
      aria-labelledby="headingforty4"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open <b>StrinZ</b> on your registered phone, Go to <b>Chat</b>
          </li>
          <li>
            <b>Click Settings Icon</b>
          </li>
          <li>
            <b>Click Terms and conditions,</b> you will be redirected to{" "}
            <b> Terms and conditions</b>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingforty5">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseforty5"
        aria-expanded="false"
        aria-controls="collapseforty5"
      >
        How to access Help to use StrinZ App?
      </button>
    </h2>
    <div
      id="collapseforty5"
      className="accordion-collapse collapse"
      aria-labelledby="headingforty5"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ol>
          <li>
            Open<b> StrinZ</b> on your registered phone, Go to <b>Chat</b>
          </li>
          <li>
            <b>Click Settings Icon</b>
          </li>
          <li>
            <b>Click Help,</b> you will be redirected to <b>Help Center</b>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingforty6">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseforty6"
        aria-expanded="false"
        aria-controls="collapseforty6"
      >
        How to change Registered Mobile Number for StrinZ App?
      </button>
    </h2>
    <div
      id="collapseforty6"
      className="accordion-collapse collapse"
      aria-labelledby="headingforty6"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <p>
          In current version, you cannot change your registered number, we are
          working continuously to improve user’s experience.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingforty6">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseforty6"
        aria-expanded="false"
        aria-controls="collapseforty6"
      >
        How to change Mobile Phone Device for StrinZ App?
      </button>
    </h2>
    <div
      id="collapseforty6"
      className="accordion-collapse collapse"
      aria-labelledby="headingforty6"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <p>
          In current version, you cannot change your device, we are working
          continuously to improve user’s experience.
        </p>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingforty8">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseforty8"
        aria-expanded="false"
        aria-controls="collapseforty8"
      >
        How to Protect your StrinZ account ?
      </button>
    </h2>
    <div
      id="collapseforty8"
      className="accordion-collapse collapse"
      aria-labelledby="headingforty8"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <ul>
          <li>
            Never share your 4-digit Verification code or 4-digit PIN with
            others.
          </li>
          <li>
            Set a device code and be aware of who has physical access to your
            phone. Someone who has physical access to your phone could use your
            StrinZ App account without your permission.
          </li>
          <li>Beware of suspicious emails or messages</li>
          <li>
            Be careful if you receive a message or an email asking you to share
            or reset your PIN or registration code. If you did not request this,
            do not click on the link. Someone could be trying to access your
            phone number on <b>StrinZ App</b>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

      
    </div>
    <div className='col-md-1'></div>
  </div>
</div>

</section>
    </>
  )
}
