import React from 'react'
export default function Career() {
  return (
    <>
   <section className="page-bread">
<div className='container'>
    <div className='row'>
        <div className='col-md-12 text-center'>
            <h2>Career</h2>
            
        </div>
    </div>
</div>
</section> 
   <section className='career'>
    <div className='container'>
        <div className='row'>
            <div className='col-md-6'>
        <h2>Career</h2>
        <h4>Phone Number</h4>
        <p>Please provide the phone number you use for your WhatsApp account.</p>
        <form>
        <div className="mb-3">
    <label htmlFor="inputPhone" className="form-label">
      Phone number
    </label>
    <div className="input-group">
      <select className="custom-select" id="countryCode" required="">
        <option value={+1}>+1 (USA)</option>
        <option value={+44}>+44 (UK)</option>
      </select>
      <input
        type="tel"
        className="form-control"
        id="inputPhone"
        placeholder="123-456-7890"
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        required=""
      />
    </div>
  </div>
  <div className="mb-3">
    <label htmlFor="inputEmail" className="form-label">
    Email Address
    </label>
    <input
      type="email"
      className="form-control"
      id="inputEmail"
      placeholder="name@example.com"
      required=""
    />
  </div>
  <div className="mb-3">
    <label htmlFor="inputEmail" className="form-label">
    Confirm Email Address
    </label>
    <input
      type="email"
      className="form-control"
      id="inputEmail"
      placeholder="name@example.com"
      required=""
    />
  </div>
  <div className="mb-3">
    <label htmlFor="inputEmail" className="form-label">
    Please enter your message below.
    </label>
    <textarea
  className="form-control"
  id="exampleFormControlTextarea1"
  rows={3}
  defaultValue={""}
/>
  </div>
  <button type="submit" className="btn download     ">
    Submit
  </button>
</form>



            </div>
            <div className='col-md-6'></div>
        </div>
    </div>
   </section>
    </>
  )
}
