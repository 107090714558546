import React from 'react'
import { Link } from 'react-router-dom';
import WEBLOGO from '../assets/strinz-logo.png';
export default function Header() {
  const scrollToContact = () => {
    const contactSection = document.getElementById('features');
    if (contactSection) {
      const yOffset = contactSection.offsetTop - 50; // Adjust offset as needed
      window.scrollTo({ top: yOffset, behavior: 'smooth' });
    }
  };
  const scrollTowork = () => {
    const contactSection = document.getElementById('work');
    if (contactSection) {
      const yOffset = contactSection.offsetTop - 50; // Adjust offset as needed
      window.scrollTo({ top: yOffset, behavior: 'smooth' });
    }
  };
  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container">
    <Link to="" className="navbar-brand">
    <img src={WEBLOGO} alt="BigCo Inc. logo"/>
    </Link>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <button className="menubtn" onClick={scrollTowork}>How it Works</button>
        </li>
        <li className="nav-item ">
          <button className="menubtn" onClick={scrollToContact}>Features</button>
        </li>
        <li className="nav-item">
        <Link className="nav-link" to="/faq">FAQs</Link>
       </li>
        <li className="nav-item">
        <button type="button" className="btn download" data-bs-toggle="modal"
    data-bs-target="#exampleModal">Download</button>
        </li>
      </ul>
      
    </div>
  </div>
</nav>

    </>
  )
}
